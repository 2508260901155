export const tools = {
    cssTransitionDuration: null,
    userDevice: null,
    collapsibles: {},

    getCSSTransitionDuration() {
        if(this.cssTransitionDuration) return this.cssTransitionDuration
        else {
            let stringVal = window.getComputedStyle(document.body).getPropertyValue('--stdSpeed');
            if(stringVal) {
                this.cssTransitionDuration = stringVal * 1;
            } else {
                this.cssTransitionDuration = 200;
            }
        }
        return this.cssTransitionDuration;
    },

    cssTransitionPromise(delay) {
        return new Promise((res, rej) => {
            setTimeout(() => {
                res(true);
            }, this.getCSSTransitionDuration() + delay);
        })
    },

    ajxGet(url, options) {
        tools.ajx('GET', url, options)
    },

    ajxPost(url, options) {
        tools.ajx('POST', url, options)
    },

    ajx(method, url, options) {
        let req = null;
        if (url) {
            req = new XMLHttpRequest();

            req.onreadystatechange = function () {

                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    if (options.fallback) {
                        options.fallback(req.response);
                    }
                }
            };

            let params = options.body;
            if(params) {
                req.open(method, url, true);
                req.setRequestHeader('Content-type', 'application/json');
            } else {
                if (options.formData && options.formData instanceof FormData) {
                    params = '';
                    params += params.indexOf('?') === -1 ? '?' : '&';
                    for (let entry of options.formData.entries()) {
                        params += entry[0] + '=' + entry[1] + '&';
                    }
                    params = params.substr(0, params.length - 1);
                }

                req.open(method, url, true);
                if(params) {
                    req.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                }
            }
            req.responseType = options.responseType || 'document';
            req.send(params);
        } else if(options.fallback){
            options.fallback({error: 'url not provided'})
        }
        return req;
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    peerCompare(list, comparisonFn) {
        if(list && comparisonFn) {
            for(let i = 0; i < list.length; i++) {
                for(let j = i+1; j < list.length; j++) {
                    comparisonFn(list[i], list[j]);
                }
            }
        }
    },

    getCurrentURL : function(excludeParams){
        var url = window.location.href;
        if(excludeParams){
            var i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            var j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    isEditMode() {
        return document.body.classList.contains('editmode');
    },

    isAuthorInstance() {
        return document.body.classList.contains('author');
    },

    removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    },

    doOnWindowResizeList: [],
    doOnScrollEndList: [],
    doOnScrollList: [],
    windowScrolListenerInitialised: false,
    windowResizeListenerInitialised: false,

    scrollToElt(parent, elt, margin = 0, duration = 300, fb) {
        let destination = (parent === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop);
        destination += elt.getBoundingClientRect().top + margin;
        this.scrollTo(parent, destination, duration, fb);
    },

    scrollTo(elt, destination, duration = 300, fb) {

        let start, distance, timeStart;

        function proceed() {
            start = elt === window ? (window.pageYOffset || window.scrollY) : elt.scrollTop;
            distance = destination - start;
            timeStart = null;
            requestAnimationFrame(loop);
        }

        function loop(time) {
            if (!timeStart) {
                timeStart = time;
            }

            let timeElapsed = time - timeStart;

            if (elt === window) {
                window.scrollTo(0, ease(timeElapsed, start, distance, duration))
            } else {
                elt.scrollTop = ease(timeElapsed, start, distance, duration);
            }

            if (timeElapsed < duration) {
                requestAnimationFrame(loop)
            } else {
                if (elt === window) {
                    window.scrollTo(0, destination)
                } else {
                    elt.scrollTop = destination;
                }
                timeStart = false;
                if (fb) {
                    fb(elt);
                }
            }
        }

        proceed();

        function ease(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b
        }
    },

    easeTo(start, target, action, duration = 300) {
        if (start && target && action) {
            let distance, timeStart;

            function proceed() {
                distance = target - start;
                timeStart = null;
                requestAnimationFrame(loop);
            }

            function loop(time) {
                if (!timeStart) {
                    timeStart = time;
                }
                let timeElapsed = time - timeStart;
                //todo: result rounding as param
                action(Math.round(ease(timeElapsed, start, distance, duration)));
                if (timeElapsed < duration) {
                    requestAnimationFrame(loop)
                } else {
                    action(target);
                    timeStart = false;
                }
            }

            proceed();

            function ease(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return c / 2 * t * t + b;
                t--;
                return -c / 2 * (t * (t - 2) - 1) + b
            }
        }
    },

    doOnWindowResize(action) {
        if (action && action.fn) {
            this.doOnWindowResizeList.push(action);
            if(!this.windowResizeListenerInitialised) {
                tools.windowResizeListener();
            }
        }
    },

    onWindowResizeActions() {
        tools.each(this.doOnWindowResizeList, action => {
            action.fn(action.arg);
        });
    },

    windowResizeListener() {
        let timeoutFn = null;
        window.onresize = (e) => {
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onWindowResizeActions();
            }, 100);
        };
        tools.windowResizeListenerInitialised = true;
    },

    doOnScroll(action, scrollEnd=true, duringScroll=false) {
        if (action && action.fn) {
            if(scrollEnd) {
                this.doOnScrollEndList.push(action);
            }
            if(duringScroll) {
                this.doOnScrollList.push(action)
            }
            if(!tools.windowScrolListenerInitialised) {
                tools.windowScrollListener();
            }
        }
    },

    onScrollActions(yPosition) {
        tools.each(this.doOnScrollList, action => {
            action.fn(action.arg, yPosition)
        });
    },

    onScrollEndActions(yPosition) {
        tools.each(this.doOnScrollEndList, action => {
            action.fn(action.arg, yPosition)
        });
    },

    windowScrollListener(fct) {
        let timeoutFn = null;
        window.addEventListener('scroll', (e) => {
            tools.onScrollActions(window.pageYOffset || window.scrollY);
            if(timeoutFn != null) {window.clearTimeout(timeoutFn);}
            timeoutFn = setTimeout(() => {
                tools.onScrollEndActions(window.pageYOffset || window.scrollY);
            }, 100);
        })
        tools.windowScrolListenerInitialised = true;
    },

    stdPromise() {
        return new Promise((res, rej) => {
            setTimeout(() => {
                res(true)
            }, this.cssTransitionDuration);
        })
    },

    overlayHandler: null,
    overlayStack: [],
    overlayVisible: false,

    initOverlayHandler(overlay= new Overlay()) {
        if(!tools.isEditMode() && overlay) {
            this.overlayHandler = overlay;
            document.addEventListener('overlayOpen', e => tools.overlayVisible = true);
            document.addEventListener('overlayClose', e => tools.overlayVisible = false);

            document.addEventListener('keyup', e => {
                if (e.key === 'Escape') {
                    if(this.overlayStack.length > 0) {
                        this.overlayStack.pop().hide();
                    }
                }
            })
        }
    },

    addToOverlayStack(overlay = new Overlay()) {
        if(overlay) {
            this.overlayStack.push(overlay);
        }
    },

    showInOverlay(elem= new HTMLElement(), fn = null, overlayClass = null,  eoc = false, onCloseFn=null) {

        if(this.overlayHandler) {
            this.overlayHandler.display(elem, fn, overlayClass, eoc)
            if(onCloseFn) {
                this.overlayHandler.setOnClose(onCloseFn);
            }
        }
    },

    isMobileNav() {
        return window.innerWidth < 1025
    },

    setCookie: function (cname, cvalue='1', exdays) {
        let expires = '';
        if(exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            expires = "expires="+d.toUTCString();
        }
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    getCookie: function (cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
};
