import {tools} from "@/js/tools.js";

class Masonry {
    constructor(el) {
        this.el = el;
        this.masonry = this.el.querySelector('.masonry');
        this.init();
    }

    init() {
        let masonryEvents = ['load', 'resize'];
        masonryEvents.forEach(event => {
            window.addEventListener(event, e => {
                this.resizeAllMasonryItems();
            });
        })
    }

    resizeAllMasonryItems() {
        this.masonry = this.el.querySelector('.masonry');
        tools.each(this.el.getElementsByClassName('masonry-item'), item => {
            this.resizeMasonryItem(item);
        })
    }

    resizeMasonryItem(item){
        let rowGap = parseInt(window.getComputedStyle(this.masonry).getPropertyValue('grid-row-gap')),
            rowHeight = parseInt(window.getComputedStyle(this.masonry).getPropertyValue('grid-auto-rows'));

        let rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));

        /* Set the spanning as calculated above (S) */
        item.style.gridRowEnd = 'span '+rowSpan;
    }
}

export default Masonry;
