import {tools} from "@/js/tools.js";

class Filters {
    static ALL_KEYWORD = 'all';
    constructor(el, filterFn) {
        this.wrapper = el;
        this.filterFn = filterFn;

        if(this.wrapper && filterFn) {
            this.init();
        }
    }

    init() {
        //logic
        this.initInputs();
        this.buildParams();

        //user i
        this.filterButtons();
    }

    initInputs() {
        const inputs = this.wrapper.querySelectorAll('.filter input');
        this.checkboxes = new Map();
        const initCheckboxes = input => {
            if(input.type === 'checkbox' || input.type === 'radio') {

                const buttonLabel =input.parentNode.parentNode.parentNode.parentNode.querySelector('button .label'); //TODO: SHAKY -> to be improved
                const inputLabel = input.parentNode.querySelector('label'); //TODO: SHAKY -> to be improved

                if(!this.checkboxes.has(input.name)) {
                    this.checkboxes.set(input.name, []);
                }
                this.checkboxes.get(input.name).push(input);

                input.addEventListener('click', e => {
                    if(!this.clearing) {
                        this.doFilter();
                        buttonLabel.textContent = inputLabel.textContent //TODO: SHAKY -> to be improved
                    }
                })
            }
        }

        tools.each(inputs, initCheckboxes);
    }

    buildParams() {
        let params = [];
        if(this.checkboxes) {
            this.checkboxes.forEach((cb, name) => {
                const values = cb.reduce((a,b) => {
                    if(b.checked && b.value !== Filters.ALL_KEYWORD) {
                        a.push(b.value);
                    }
                    return a
                }, [])

                if(values && values.length > 0) {
                    params.push(name + "=" + values.join(','));
                }
            })
        }

        return params;
    }

    doFilter() {
        this.filterFn(this.buildParams());
    }

    feed(params) {
        if(params) {
            for(let i = 0; i < params.length; i++) {
                const param = params[i];
                const splitt = param.split('=');
                const name = splitt[0];
                const value = splitt[1];

                if(this.checkboxes) {
                    if(this.checkboxes.has(name)) {
                        this.checkboxes.get(name).forEach(input => input.checked = input.value === value)
                    }
                }
            }
        }
    }

    filterButtons() {
        const buttons = this.wrapper.querySelectorAll('.filter button');

        if(buttons) {

            const close = b => {
                b.classList.remove('open');
                b.optionsW.style.height = '0';
                b.optionsW.style.maxHeight = '0';
            }

            const open = b => {
                b.classList.add('open');
                const options = b.optionsW.firstElementChild;
                const newHeight = options.offsetHeight + 'px';
                b.optionsW.style.height = newHeight;
                b.optionsW.style.maxHeight = newHeight;
            }

            const btnClickHandler = b => {
                if(b.classList.contains('open')) close(b)
                else open(b)
            }

            const optionClickHandler = (b) => {
                tools.cssTransitionPromise().then(() => close(b));
            }

            const initButton = b => {
                b.optionsW = b.parentNode.querySelector('.options-wrapper');
                b.addEventListener('click', () => btnClickHandler(b));
                b.optionsW.addEventListener('click', () => optionClickHandler(b));
            }

            buttons.forEach(initButton);
        }
    }
}

export default Filters;
