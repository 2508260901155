
import {tools} from "@/js/tools.js";
import Pagination from "./Pagination.js";
import Filters from './Filters.js';
import Masonry from "@/js/list/Masonry.js";


class List {
    constructor(el, updateListFn=null, updateHistory=true) {
        this.el = el;
        this.filtersWrapper = el.querySelector('.filters-wrapper');
        this.queryUrl = null;
        this.filters = null;
        this.listEl = el.querySelector('.list');
        this.updateListFns = null;
        this.addUpdateListFn(updateListFn);
        this.updateHistory = updateHistory;
        this.init();
    }

    init() {
        new Pagination(this.el);
        if(this.filtersWrapper) {
            this.queryUrl = this.filtersWrapper.getAttribute('data-queryurl');
        }

        if(this.queryUrl) {
            this.filters = new Filters(this.filtersWrapper, params => this.updateList(params))
        }

        this.updateEventVisibility();

        if (this.el.querySelector(".masonry")){
            this.masonry = new Masonry(this.el);
        }
    }

    updateEventVisibility(){
        tools.each(document.querySelectorAll(".entity.event[data-event-visibility]"), eventEntry => {
            let eventDateString = eventEntry.getAttribute('data-event-visibility');
            if (eventDateString){
                if (new Date(eventDateString) < new Date()) eventEntry.style.display = "block";
            }
        })
    }

    addUpdateListFn(fn) {
        if(fn) {
            if(!this.updateListFns) {
                this.updateListFns = [];
            }
            this.updateListFns.push(fn);
        }
    }
    updateList(params = []) {
        this.listEl.classList.add('loading');
        const timeout = window.setTimeout(() => {
            this.listEl.classList.remove('loading');
        }, 7000);

        let paramString = '';
        if (params.length > 0) {
            paramString = '?' + params.join('&');
        }

        if(this.updateHistory) {
            history.pushState(null, null, tools.getCurrentURL(true) + paramString);
        }

        tools.ajxGet(this.queryUrl + paramString, {
            fallback: resp => {

                const newList = resp.querySelector('.list');

                if (newList) {
                    setTimeout(() => {
                        this.listEl.innerHTML = newList.innerHTML;
                        if(this.updateListFns) {
                            this.updateListFns.forEach(fn => fn(resp));
                        }

                        new Pagination(this.el);
                        if (this.masonry) this.masonry.resizeAllMasonryItems();
                        this.listEl.classList.remove('loading');
                        window.clearTimeout(timeout);
                    }, 200)
                }
            }
        })
    }
}

export default List;
