import Lazy from './Lazy';

class LazyFrame extends Lazy{

    constructor(selector='.lazy-iframe')   {
        super({
            targets: selector,

            loadFn: iframeWrapper => {
                const src = iframeWrapper.getAttribute('data-lazy');
                if(src) {
                    const iframe = document.createElement('iframe');
                    if(src.indexOf("youtube") !== -1){
                        iframe.onload = () => iframe.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*')
                    }
                    iframe.setAttribute('frameborder', iframeWrapper.getAttribute('frameborder'));
                    iframe.setAttribute('allow', iframeWrapper.getAttribute('allow'));
                    iframe.setAttribute('allowfullscreen', 'allowfullscreen');
                    iframeWrapper.insertAdjacentElement("beforeend", iframe);
                    iframe.src = src;
                }
            },
            threshold: 0.01,
            rootMargin: {top: 0, right: 0, left: 0, bottom: 0},
            persist: false,
            simLoadLimit: 1,
            simLoadDelay: 200,
        })
    }
}

export default LazyFrame;
