import {tools} from "@/js/tools.js";
import {lazyHandler} from "./lazy/lazyHandler.js";

export default class AJXAnchorWC extends HTMLElement {
    constructor() {
        super();
        this.pushHistory = true;
        this.clicked = false;
        this.clickListener();

    }

    clickListener() {
        if (!this.clicked && this.getAttribute('href')) {
            let targetEltSelector = this.getAttribute('data-targetelt');
            if(targetEltSelector === 'buildIframe') {
                this.handleIframe();
            } else {
                this.handleAjxRequest(targetEltSelector);
            }
        }

        if (location.hash === '#'+this.dataset.hashId) {
            setTimeout(() => {
                this.createIframe();
            }, 500)
        }
    }

    handleIframe() {
        this.pushHistory = false;
        this.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            if(!this.clicked) {
                this.createIframe()
                if (this.dataset.hashId) {
                    history.pushState('', '', `#${this.dataset.hashId}`);
                }
            }
        })
    }

    createIframe() {
        this.clicked = true;
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', this.getAttribute('href'));
        iframe.setAttribute('width', "100%");
        iframe.setAttribute('height', "100%");
        iframe.style.flexGrow = 1;
        this.showResult(iframe);
    }

    handleAjxRequest(targetEltSelector) {
        this.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            if(!this.clicked) {
                this.clicked = true;
                tools.ajxGet(this.getAttribute('href'), {
                    fallback: resp => {
                        const targetElt = resp.querySelector(targetEltSelector);
                        if (targetElt) {
                            this.showResult(targetElt);
                            this.proceedWithTargetInit(targetElt, targetEltSelector);
                        }
                    }
                })
            }
        })
    }


    showResult(displayedElt) {
        const currentUrl = tools.getCurrentURL();
        if (this.getAttribute("data-showin") === "overlay") {
            // if(this.pushHistory) history.pushState(null, null, this.getAttribute('href'))
            tools.showInOverlay(
                displayedElt,
                null,
                this.getAttribute('data-overlay-class'),
                false,
                () => {
                    if (this.dataset.hashId) {
                        history.pushState("", document.title, window.location.pathname + window.location.search)
                    }
                }
            );
        } else {
            this.disappear();
            this.parentElement.insertAdjacentElement("beforeend", displayedElt);
        }
        this.clicked = false;
    }

    disappear() {
        this.classList.add("hidden");
    }

    proceedWithTargetInit(targetElt=new HTMLElement(), targetEltSelector="") {
        lazyHandler();
    }
}
