import {tools} from "@/js/tools.js";
import LazyFrame from "@/js/lazy/LazyFrame.js";

class VideoPoster {
    constructor(el) {
        this.el = el;
        this.videoSource = el.getAttribute("data-video-src");
        this.videoId = el.getAttribute("data-video-id");
        this.poster = el.querySelector('.video-poster');
        this.btn = el.querySelector('.play-btn');
        this.iframeWrapper = el.querySelector('.sizer');
        tools.bimbim = "hooooooolé";
        if (this.videoSource != "") this.init();
    }

    init(){
        let link = '';
        if (this.videoSource.includes("vimeo")){
            link = 'https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + this.videoId + '&format=json';
        } else {
            link = 'https://youtube.com/oembed?url=http://www.youtube.com/watch?v=' + this.videoId + '&format=json';
        }

        fetch(link).then(res => res.json()).then(out => {
            let thumbnail_url = out.thumbnail_url;
            if (thumbnail_url){
                thumbnail_url = thumbnail_url.replace("-d_295x166", "-d_640");
                this.poster.style.backgroundImage = "url('"+thumbnail_url+"')";
            }
        });

        if(this.btn && this.iframeWrapper) {
            this.btn.addEventListener('click', e => this.btnClickListener(e));
        }
    }

    btnClickListener = (e) => {
        this. btn.classList.add('scale');
        new LazyFrame(this.iframeWrapper);
        setTimeout(() => {
            this.el.classList.add('video-init');
            this.btn.classList.remove('scale');
        }, 700);
    }
}

export default VideoPoster;
