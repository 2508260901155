
import {tools} from "@/js/tools.js";
import {lazyHandler} from "../lazy/lazyHandler";

class Pagination {
    constructor(wrapper, updateFn = null, pagesEltSelector = '.page', paginationEltSelector = '.pagination') {
        this.wrapper = wrapper;
        this.updateFn = updateFn;
        this.els = this.wrapper.querySelectorAll(pagesEltSelector);
        this.params = null;
        if (!tools.isEditMode() && this.els) {
            this.paginationElt = this.wrapper.querySelector(paginationEltSelector);
            if (this.paginationElt) {
                this.init(this.paginationElt);
            }
        }
    }

    init(paginationElt) {

        paginationElt.addEventListener('click', e => {

                let url = paginationElt.getAttribute('data-link');

                if (url) { //todo: url to page -> url to component
                    tools.ajxGet(url, {
                        fallback: res => {
                            if (res) {
                                const newPage = res.querySelector('.page');

                                if (newPage) {
                                    newPage.classList.add('loading');

                                    const parentNode = paginationElt.parentNode;
                                    parentNode.removeChild(paginationElt);
                                    parentNode.insertAdjacentHTML('beforeend', newPage.innerHTML);

                                    lazyHandler();

                                    if (this.updateFn) {
                                        this.updateFn(parentNode)
                                    }

                                    const newPaginationElt = parentNode.querySelector('.pagination');
                                    if (newPaginationElt) {
                                        this.init(newPaginationElt);
                                    }
                                }
                            }
                        }
                    })
                }
        })

    }

}

export default Pagination;
