import AJXAnchorWC from "@/js/AJXAnchorWC.js";
import List from '@/js/list/List.js';
import Splide from "@splidejs/splide";
import {lazyHandler} from "@/js/lazy/lazyHandler.js";
import VideoPoster from "@/js/components/VideoPoster.js";
import Overlay from "./Overlay.js";
import {tools} from "@/js/tools.js";
import Collapsible from "@/js/collapsible/Collapsible.js";

customElements.define('ajx-anchor', AJXAnchorWC);

const app = {
    init() {
        document.documentElement.classList.remove('no-js');
        document.documentElement.classList.add('loaded');
        this.navigation();
        this.eventList();
        this.videos();
        this.sliderSplide();
        this.wannaSplide();
        this.initLazy();
        this.initNewsletter();
        tools.initOverlayHandler(new Overlay());
        this.popIt();
        this.collapsible();
        this.mobileScrollTop();
        this.homeScrollContent();
        this.smeetzCustom();
        this.formCheck();
    },

    initLazy() {
        lazyHandler();
    },

    navigation() {
        let burger = document.getElementById('nav_burger');
        if (burger) {
            burger.addEventListener('click', e => {
                document.body.classList.toggle('nav-open');
            });
        }

        let mainNav = document.getElementById('main-nav');
        if (mainNav){

            let subNavs = mainNav.querySelectorAll(".has-subs");
            if (subNavs) {
                let isMobile = false;
                const subNavMobile = (e) => {
                    let li = e.target.parentElement.parentElement;
                    if (li.classList.contains("active") && !li.classList.contains("open")){
                        li.classList.remove("active");
                    } else {
                        li.classList.toggle("open");
                    }
                }
                if (tools.isMobileNav()) {
                    isMobile = true;
                    tools.each(subNavs, subNav => subNav.addEventListener('click', subNavMobile));
                }

                window.addEventListener('resize', e => {
                    if (tools.isMobileNav() && isMobile) {
                        isMobile = true;
                        subNavs.forEach(subNav => {
                            let icon = subNav.querySelector('> a .icon');
                            icon.addEventListener('click', subNavMobile);
                        })
                    // } else {
                    //     isMobile = false;
                    //     subNavs.forEach(subNav => subNav.removeEventListener('click', subNavMobile))
                    }
                });
            }
        }
    },

    eventList() {
        const eventListElts = document.querySelectorAll('.event-list-wrapper');

        if (eventListElts) {
            eventListElts.forEach(list => new List(list));
        }
    },

    videos() {
        const videoWrappers = document.querySelectorAll('.video-embed.poster');

        if (videoWrappers) {
            videoWrappers.forEach(video => new VideoPoster(video));
        }

    },

    wannaSplide() {
        const carousels = document.querySelectorAll('.splide.splide-carousel');
        if(carousels) {
            let options = {
                type    : 'fade',
                autoplay: false,
                rewind: true,
                arrows: false,
                pagination: false,
                pauseOnHover: false,
                easing: 'ease',
                speed: 3800,
                rewindSpeed: 3800,
                interval: 4000,
                perPage : 1
            }
            let autoplayIt = 0;
            carousels.forEach((s, index) => {
                let sp = new Splide(s, options).mount();

                setTimeout(() => {
                    let autoplay = sp.Components.Autoplay;
                    autoplay.play();
                }, autoplayIt);
                autoplayIt += 1800;
            })
        }

        const eventsSplide = document.querySelectorAll('.splide.splide-events');
        if(eventsSplide) {
            const options = {
                type: 'loop',
                perPage: 3,
                pagination: false,
                gap: 15,
                breakpoints: {
                    640: {
                        perPage: 1,
                        padding: {left: '23%', right: '23%'},
                    },
                }
            }
            eventsSplide.forEach(s => new Splide(s, options).mount())
        }
    },

    sliderSplide(){
        const sliders = document.querySelectorAll('.slider');

        const imagesOptions = {
            type    : 'loop',
            pagination: false,
            perPage : 1,
            autoplay: true
        }
        const textOptions = {
            type    : 'loop',
            arrows: false,
            perPage : 1,
            autoplay: true
        }

        sliders.forEach(slider => {
            let textS, imageS;
            let imageSplide = slider.querySelector('.splide.splide-images');
            let textSplide = slider.querySelector('.splide.splide-texts');

            if (textSplide) {
                textS = new Splide(textSplide, textOptions);
            }
            if (imageSplide) {
                imageS = new Splide(imageSplide, imagesOptions);
            }
            if (imageSplide && textSplide){
                textS.sync(imageS);
                textS.mount();
                imageS.mount();
            } else if (textSplide) {
                textS.mount();
            } else if (imageSplide) {
                imageS.mount();
            }

        })
    },

    initNewsletter(){
        let footerNewsletterSignUp = document.getElementById('footer_newsletter_signup');
        if (footerNewsletterSignUp){
            let scriptSource = footerNewsletterSignUp.getAttribute("data-script-source");
            let emailInput = footerNewsletterSignUp.querySelector('.nl-email');
            emailInput.addEventListener('focus', e => {
                console.log("TEST NEWSLETTER")
                let script = document.createElement("script");
                script.src = scriptSource;
                footerNewsletterSignUp.appendChild(script);
                script.addEventListener("load", e => {
                    let email = footerNewsletterSignUp.querySelector('input[data-type="e-mail"]');
                    email.focus();
                    email.value = emailInput.value;
                })
            });
        }
    },

    popIt() {
        if(tools.isAuthorInstance() || !(tools.isEditMode() || tools.getCookie("maag_popup_closed"))) {
            const popupWindow = document.querySelector('#popup');
            if(popupWindow) {
                setTimeout(() => {
                    tools.showInOverlay(popupWindow, () => lazyHandler(), "popup-overlay", true, () => {
                        tools.setCookie("maag_popup_closed")
                    })
                }, 1000)
            }
        }
    },

    collapsible() {
        const collapsibleElts = document.querySelectorAll('.collapsible-entry');

        const initCollapsibleFn = wrapper => {
            const btns = wrapper.querySelectorAll('button.colbtn');
            const collapsibleElt = wrapper.querySelector('.collapsible-wrapper');
            const collapsibleArea = wrapper.querySelector('.collapsible-area');

            if(btns && collapsibleElt && collapsibleArea) {
                new Collapsible('', wrapper, btns, collapsibleElt, collapsibleArea);
            }
        }

        tools.each(collapsibleElts, initCollapsibleFn)
    },

    mobileScrollTop(){
        let scrollTopElt = document.getElementById("scroll_top");
        if (scrollTopElt){
            const scrollTop = function () {
                if(window.scrollY!=0) {
                    setTimeout(function() {
                        window.scrollTo(0,window.scrollY-30);
                        scrollTop();
                    }, 5);
                }
            }
            scrollTopElt.addEventListener("click", scrollTop);
        }
    },

    homeScrollContent(){
        let scrollToContent = document.getElementById('scroll_home_content');
        let homeTeaserHeader = document.getElementById('home_teaser_header');
        if (scrollToContent && homeTeaserHeader){
            let homeTeaserHeaderHeight, scrollToContentTop,scrollToContentHeight;
            const checkHeight = () => {
                homeTeaserHeaderHeight = homeTeaserHeader.offsetHeight;
                scrollToContentHeight = scrollToContent.offsetHeight;
                scrollToContentTop = scrollToContent.offsetTop;
                if (window.innerHeight < homeTeaserHeaderHeight){
                    scrollToContent.style.position = "fixed";
                } else {
                    scrollToContent.style.position = "absolute";
                }
            }
            const checkScroll = () => {

                if ((window.innerHeight + window.scrollY) < scrollToContentTop){
                    scrollToContent.style.position = "fixed";
                } else {
                    scrollToContent.style.position = "absolute";
                }
            }

            checkHeight();
            window.onresize = (e) => {
                checkHeight();
            }
            window.onscroll = () => checkScroll();
        }
    },

    smeetzCustom(){
        tools.each(document.querySelectorAll('.smeetz-cta'), smtzDiv => {
            let color = smtzDiv.getAttribute('data-color');
            const listener = () => {
                let ctaBtn = smtzDiv.querySelector('a');
                let onclickString = ctaBtn.getAttribute('onclick');
                onclickString = onclickString.replace("lightbox: true,", "lightbox: true, ocol:'#"+this.rgba2hex(color)+"',")
                ctaBtn.setAttribute('onclick', onclickString)
            }
            smtzDiv.addEventListener('DOMSubtreeModified', listener, { once: true });
        })
    },

    rgba2hex(orig) {
        let a, isPercent,
            rgb = orig.replace(/\s/g, '').match(/(\d+),(\d+),(\d+),?([^,\s]+)?/i),
            alpha = (rgb && rgb[4] || "").trim(),
            hex = rgb ?
                (rgb[1] | 1 << 8).toString(16).slice(1) +
                (rgb[2] | 1 << 8).toString(16).slice(1) +
                (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

        if (alpha !== "") {
            a = alpha;
        } else {
            a = 1;
        }
        // multiply before convert to HEX
        a = ((a * 255) | 1 << 8).toString(16).slice(1)
        hex = hex + a;

        return hex;
    },
    formCheck() {
        document.querySelectorAll('input[type=file]').forEach(fileField => {
            let maxSize = fileField.dataset.maxSize;
            let errorText = fileField.dataset.errorText;
            fileField.onchange = function() {
                console.log("file size: "+ this.files[0].size)
                console.log("Max size: "+ maxSize * 1048576)
                if(this.files[0].size > (maxSize * 1048576)) {
                    alert(errorText);
                    this.value = "";
                }
            };

        });
    }
}
window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (let i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});
