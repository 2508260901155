import {tools} from "@/js/tools.js";

class Overlay {
    constructor(overlayIdPrefix='shared') {
        this.id = overlayIdPrefix + '-overlay';
        this.el = document.querySelector('#' + this.id);
        this.wrapper = null;
        this.content = null;
        this.closeBtn = null;
        this.customClass = [];
        this.currentContentId = null;
        this.init();
        this.visible = this.el.classList.contains('visible');
    }

    init() {

        if (!this.el) {
            this.injectOverlayElement();
        }

        this.wrapper = this.el.querySelector('#' + this.id + '-wrapper');
        this.scrollable = this.el.querySelector('#' + this.id + '-scroll');
        this.content = this.el.querySelector('#' + this.id + '-content');
        this.closeBtn = this.el.querySelector('.overlay-close');

        if(this.closeBtn) {
            this.closeBtn.onclick = () => console.log("hoho");
        }


        this.el.addEventListener('click', e => {
            if (e.target.id === this.id) {
                this.closeBtn.click();
            }
        })
    }

    injectOverlayElement() {
        let overlayWrapper = document.createElement('div');
        overlayWrapper.id = this.id;
        overlayWrapper.classList.add('overlay')
        overlayWrapper.innerHTML =
            '<div id="'+this.id +'-wrapper" class="overlay-wrapper">' +
                '<div id="'+this.id+'-scroll" class="overlay-scroll">' +
                    '<button class="overlay-close close-btn">' +
                        '<svg x="0" y="0" xml:space="preserve" width="37.7" height="37.7" xmlns="http://www.w3.org/2000/svg"><style>.ov-close{fill:none;stroke:#fff;stroke-width:1.5}</style><g transform="rotate(45 15.672 6.604)"><path class="ov-close" d="M13.8 12.9h25.6"/><path class="ov-close" d="M26.4.3v25.6"/></g><circle class="ov-close" cx="18.85" cy="18.85" r="18.1"/></svg>' +
                    '</button>' +
                    '<div id="'+this.id+'-content" class="overlay-content"></div>' +
                '</div>' +
            '</div>';

        document.body.insertAdjacentElement('beforeend', overlayWrapper);

        this.el = overlayWrapper;
    }

    display(elem, fn, overlayClass, emtyOnClose=false) {
        if (this.visible) {
            this.hide();
        } else {
            document.dispatchEvent(new Event('overlayOpen'));
        }

        if(overlayClass) {
            this.removeCustomClass();
            this.setCustomClass(overlayClass);
        }

        if(!elem.id || this.currentContentId !== elem.id) {
            this.content.innerHTML = elem.outerHTML;
            tools.each(this.el.querySelectorAll('.close-btn'), btn => {
                btn.onclick = () => this.hide();
            })
            if (fn) {
                fn(this.content.firstElementChild);
            }
        }

        if(emtyOnClose) {
            this.content.classList.add('eoc');
        } else if(elem.id){
            this.currentContentId = elem.id;
        }

        this.show();
    }

    setOnClose(fn) {
        this.onClose = fn;
    }

    show() {
        if (!this.visible) {
            this.content.scrollTop = 0;
            this.visible = true;
            this.el.classList.add('visible');
            document.body.classList.add('fixed')
            tools.addToOverlayStack(this);
            return true;
        }

        return false;
    }

    hide() {

        if (this.visible) {
            this.visible = false;
            this.el.classList.remove('visible');
            tools.cssTransitionPromise(50).then(() => {
                //empty on close
                if(this.content.classList.contains('eoc')) {
                    this.content.innerHTML = '';
                    this.currentContentId = null;
                    this.content.classList.remove('eoc');
                }
                document.dispatchEvent(new Event('overlayClose'));
                document.body.classList.remove('fixed')

                if(this.onClose) {
                    this.onClose();
                    this.onClose = null;
                }
            })

            return true;
        }
        return false;
    }

    isVisible() {
        return this.visible;
    }

    setCustomClass(classs) {
        if(classs) {
            this.customClass.push(classs);
            this.el.classList.add(classs);
        }
    }

    hasCustomClass(classs) {
        return this.el.classList.contains(classs);
    }

    removeCustomClass(className) {
        if(this.customClass) {
            if(className) {
                this.customClass = this.customClass.reduce(
                    (a, b) => {
                        if(b !== className) {
                            a.push(b);
                        }
                        return a;
                        }
                    , [])
                this.el.classList.remove(className);
            } else {
                this.customClass.forEach(c => this.el.classList.remove(c))
                this.customClass = [];
            }
        }
    }
}

export default Overlay;

